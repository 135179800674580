let origin = window.ORIGIN;
//提交捐赠
const postDonateUrl = `${origin}/gateway/hc-serve/miniapi/donate/donateApply`;
//我的捐赠
const myDonateUrl = `${origin}/gateway/hc-serve/miniapi/donate/myDonateList`;
//捐赠列表
const donateListUrl = `${origin}/gateway/hc-serve/miniapi/donate/donateList`;
//我的捐赠详情
const myDonateDetailUrl = `${origin}/gateway/hc-serve/miniapi/donate/myDonateDetail`;
//捐赠详情
const donateDetailUrl = `${origin}/gateway/hc-serve/miniapi/donate/donateDetail`;
//取消捐赠
const cancelUrl = `${origin}/gateway/hc-serve/miniapi/donate/myDonateCancel`;

export {
  postDonateUrl,
  myDonateUrl,
  donateListUrl,
  myDonateDetailUrl,
  donateDetailUrl,
  cancelUrl,
};
